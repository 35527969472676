import React, { useState } from "react";

function VoicemailAccessNumber({ number, pin }) {
  const [revealed, setRevealed] = useState(false);

  return (
    <div className="voicemail-content__access">
      <p className="voicemail-content__access-number">{number}</p>
      {revealed ? (
        <p className="voicemail-content__access-number">{pin}</p>
      ) : (
        <button className="button" onClick={() => setRevealed(true)}>
          See your PIN
        </button>
      )}
    </div>
  );
}

export default React.memo(VoicemailAccessNumber);
