import PropTypes from "prop-types";
import React, { Fragment, PureComponent } from "react";

class Confirm extends PureComponent {
  render() {
    const { aboutCookies, allowCookies } = this.props;

    return (
      <Fragment>
        <h2 className="cookie-banner__title">Hello there!</h2>
        <div className="cookie-banner__content">
          <p className="cookie-banner__text">
            This website uses cookies in order to provide you with the most
            relevant information. Please accept cookies for the best experience.
          </p>
        </div>
        <div className="cookie-banner__buttons">
          <button
            className="button button--medium"
            onClick={() => allowCookies("all")}
            type="button"
          >
            Allow &amp; Continue
          </button>
          <button
            className="cookie-banner__link-button"
            onClick={aboutCookies}
            type="button"
          >
            Learn about cookies
          </button>
        </div>
      </Fragment>
    );
  }
}

Confirm.propTypes = {
  aboutCookies: PropTypes.func.isRequired,
  allowCookies: PropTypes.func.isRequired
};

export default Confirm;
