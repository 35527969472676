import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import debounce from "lodash/debounce";
import uniqueId from "lodash/uniqueId";
import { pluralize } from "../../utils";

class HelpSearchFooter extends PureComponent {
  render() {
    return (
      <div className="search-results__footer">
        <img
          alt=""
          className="search-results__footer-icon"
          src="https://placehold.it/44x44"
          role="presentation"
        />
        <p className="search-results__footer-text">
          <strong>Can’t see what you’re after here?</strong>
          Just get in touch and we’ll be happy to help.
        </p>
        <button className="button button--large search-results__footer-button js-ask">
          Get in touch
        </button>
      </div>
    );
  }
}

class HelpSearchInput extends PureComponent {
  render() {
    const { onChange } = this.props;

    return (
      <input
        className="input input--large page-header__input"
        onChange={onChange}
        placeholder="Type here to ask a question or search our help centre…"
        type="search"
      />
    );
  }
}

HelpSearchInput.propTypes = {
  onChange: PropTypes.func.isRequired
};

class HelpSearchResult extends PureComponent {
  render() {
    const { snippet, title, url } = this.props;

    return (
      <li className="search-results__item">
        <h3 className="search-results__item-title">
          <a className="link" href={url}>
            {title}
          </a>
        </h3>
        <p className="search-results__item-text">{snippet}</p>
      </li>
    );
  }
}

HelpSearchResult.propTypes = {
  snippet: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

class HelpSearchResults extends PureComponent {
  render() {
    const { results } = this.props;

    return (
      <ul className="search-results__list">
        {results.map(result => (
          <HelpSearchResult key={result.id} {...result} />
        ))}
      </ul>
    );
  }
}

HelpSearchResults.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object).isRequired
};

class HelpSearchTitle extends PureComponent {
  render() {
    const { count } = this.props;

    return (
      <h2 className="search-results__title">
        We’ve found {pluralize(count, "page", "pages")} that might help you…
      </h2>
    );
  }
}

HelpSearchTitle.propTypes = {
  count: PropTypes.number.isRequired
};

const shouldCancelRequest = (firstRequestId, secondRequestId) =>
  firstRequestId !== secondRequestId;

const initial = Object.freeze({
  error: false,
  loaded: false,
  loading: false,
  results: []
});

class HelpSearch extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...initial
    };
  }

  handleChange = event => {
    const requestId = (this.requestId = uniqueId("request_"));
    const query = event.target.value;

    this.setState(
      {
        ...initial,
        loading: true
      },
      () => {
        this.search(query, requestId);
      }
    );
  };

  render() {
    const { loaded, results } = this.state;

    return (
      <div className="page-header__search">
        <HelpSearchInput onChange={this.handleChange} />
        {loaded && (
          <div className="search-results">
            <div className="search-results__content">
              <HelpSearchTitle count={results.length} />
              <HelpSearchResults results={results} />
            </div>
            <HelpSearchFooter />
          </div>
        )}
      </div>
    );
  }

  search = debounce(
    async (query, requestId) => {
      if (shouldCancelRequest(requestId, this.requestId)) return;

      delete this.requestId;

      const encodedQuery = encodeURIComponent(query);
      const response = await fetch(`/help/search?q=${encodedQuery}`);
      const results = await response.json();

      this.setState({
        loaded: true,
        loading: false,
        results
      });
    },
    2000,
    { maxWait: 2000 }
  );
}

export default HelpSearch;
