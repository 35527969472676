import React, { PureComponent, useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import ChooseLocalNumber from "./ChooseLocalNumber";
import ChooseNationalNumber from "./ChooseNationalNumber";
import HiddenInput from "./HiddenInput";
import NumberConfirmationDialog from "./NumberConfirmationDialog";
import NumberContext from "../../contexts/NumberContext";

class NumberStep extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      chosenNumber: null,
      confirmed: false
    };
  }

  handleCancel = () => {
    this.setState({ chosenNumber: null });
  };

  handleConfirm = () => {
    this.setState(
      {
        confirmed: true
      },
      () => {
        const form = this.container.closest("form");

        const event = new Event("submit", {
          bubbles: true,
          cancelable: true
        });

        form.dispatchEvent(event);

        form.submit();
      }
    );
  };

  handleNumberChosen = chosenNumber => {
    this.setState({ chosenNumber });
  };

  render() {
    const { chosenNumber, confirmed } = this.state;
    const { pricePerMonth, includedMinutes, type } = this.props;

    const token = chosenNumber ? chosenNumber.token : "";

    return (
      <div
        ref={container => {
          this.container = container;
        }}
      >
        <NumberContext.Provider
          value={{ chosenNumber, numberChosen: this.handleNumberChosen }}
        >
          <React.Fragment>
            {confirmed && <HiddenInput name="continue" value={true} />}
            <HiddenInput name="number_token" value={token} />
            {type === "local" ? (
              <ChooseLocalNumber />
            ) : (
              <ChooseNationalNumber />
            )}
            {chosenNumber !== null && (
              <NumberConfirmationDialog
                number={chosenNumber}
                onCancel={this.handleCancel}
                onConfirm={this.handleConfirm}
                pricePerMonth={pricePerMonth}
                includedMinutes={includedMinutes}
              />
            )}
          </React.Fragment>
        </NumberContext.Provider>
      </div>
    );
  }
}

NumberStep.propTypes = {
  pricePerMonth: PropTypes.number.isRequired,
  includedMinutes: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired
};

export default NumberStep;
