import "core-js/stable";
import "regenerator-runtime/runtime";
import "lazysizes";

const ActiveStorage = require("@rails/activestorage");
const RailsUJS = require("@rails/ujs");
const ReactRailsUJS = require("react_ujs");

ActiveStorage.start();
RailsUJS.start();

ReactRailsUJS.detectEvents();

// TODO: Perhaps change the context here to only load in the required components
// https://webpack.js.org/guides/dependency-management/#requirecontext
ReactRailsUJS.useContext(require.context("components/application", true));

function setupMenu() {
  const hamburger = document.querySelector(".js-hamburger");
  const menu = document.querySelector(".js-menu");
  const toggle = document.querySelector(".js-toggle-menu");
  const toggleText = document.querySelector(".js-toggle-text");

  if (!hamburger || !menu || !toggle || !toggleText) {
    return;
  }

  const onClick = event => {
    event.preventDefault();

    document.body.classList.toggle("is-dialog-open");

    hamburger.classList.toggle("is-active");
    menu.classList.toggle("is-visible");
    toggle.classList.toggle("is-active");

    const text = menu.classList.contains("is-visible") ? "Close" : "Menu";

    toggleText.textContent = text;
  };

  toggle.addEventListener("click", onClick, false);
}

document.addEventListener(
  "DOMContentLoaded",
  () => {
    document.addEventListener(
      "click",
      () => {
        if (!event.target.matches(".js-ask")) return;

        if (window.chatbox && window.chatbox.available) {
          return window.chatbox.open();
        }

        const help = document.querySelector(".js-help");

        help.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      },
      false
    );

    setupMenu();
  },
  false
);
