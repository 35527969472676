import PropTypes from "prop-types";
import React from "react";

function FormRowColumn({ children }) {
  return <div className="form-row__column">{children}</div>;
}

FormRowColumn.propTypes = {
  children: PropTypes.node.isRequired
};

export default React.memo(FormRowColumn);
