import PropTypes from "prop-types";
import React from "react";
import ChangeForwardingPopout from "./ChangeForwardingPopout";

class ChangeForwardingBlock extends React.PureComponent {
  constructor(props) {
    super(props);

    const { destinationNumber } = props;

    this.state = {
      destinationNumber,
      error: "",
      open: false,
      submitting: false
    };
  }

  handleChange = destinationNumber => {
    this.setState({
      open: false,
      destinationNumber
    });
  };

  handleClose = () => {
    this.setState({
      error: "",
      open: false
    });
  };

  handleError = error => {
    this.setState({
      error: error.message,
      submitting: false
    });
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleSubmit = destinationNumber => {
    const { submitting } = this.state;

    if (submitting) {
      return;
    }

    this.setState(
      {
        error: "",
        submitting: true
      },
      () => this.post(destinationNumber)
    );
  };

  handleSuccess = data => {
    this.setState({
      destinationNumber: data.destination_number,
      open: false,
      submitting: false
    });
  };

  post = destinationNumber => {
    const { authenticityToken } = this.props;

    fetch("/dashboard/forwarding", {
      body: JSON.stringify({
        _authenticity_token: authenticityToken,
        destination_number: destinationNumber
      }),
      credentials: "same-origin",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "PATCH"
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("We couldn't update your forwarding number. Please contact our support team if this issue persists.");
        }

        return response.json();
      })
      .then(this.handleSuccess)
      .catch(this.handleError);
  };

  render() {
    const { destinationNumber, error, open, submitting } = this.state;
    const { primaryNumber } = this.props;

    const [area, ...numberParts] = primaryNumber.split(" ");
    const onClick = open ? this.handleClose : this.handleOpen;

    const number = numberParts.join(" ");

    return (
      <section className="dashboard-destination">
        <h2 className="dashboard-destination__title">
          <span className="dashboard-destination__title-span">{area}</span>
          <span className="dashboard-destination__title-span--bold">
            {number}
          </span>
        </h2>
        <div className="dashboard-destination__text">
          This is your number and is currently forwarded to{" "}
          <strong>{destinationNumber}</strong>
        </div>
        <div className="dashboard-destination__footer">
          <button
            className="button button--medium button--white dashboard-destination__button"
            onClick={onClick}
          >
            Change forwarding
          </button>
          {open && (
            <ChangeForwardingPopout
              error={error}
              number={destinationNumber}
              onClose={this.handleClose}
              onSubmit={this.handleSubmit}
              submitting={submitting}
            />
          )}
        </div>
      </section>
    );
  }
}

ChangeForwardingBlock.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  destinationNumber: PropTypes.string.isRequired,
  primaryNumber: PropTypes.string.isRequired
};

export default React.memo(ChangeForwardingBlock);
