import PropTypes from "prop-types";
import React from "react";

function FormErrors({ errors, title }) {
  return (
    <div className="error">
      <h2 className="error-heading">{title}</h2>
      <ul className="error-list">
        {errors.map(message => (
          <li key={message}>{message}</li>
        ))}
      </ul>
    </div>
  );
}

FormErrors.propTypes = {
  errors: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

export default React.memo(FormErrors);
