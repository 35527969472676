import PropTypes from "prop-types";
import React from "react";

function FormRow({ children, modifier }) {
  const classNames = ["form-row"];

  if (modifier) {
    classNames.push(`form-row--${modifier}`);
  }

  return <div className={classNames.join(" ")}>{children}</div>;
}

FormRow.propTypes = {
  children: PropTypes.node.isRequired,
  modifier: PropTypes.string
};

export default React.memo(FormRow);
