import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

const RegionSelectInput = React.forwardRef(
  ({ expanded, onBlur, onChange, onFocus, onKeyDown, value }, ref) => {
    const className = classnames({
      input: true,
      "input--large": true,
      "region-select__input": true,
      "region-select__input--expanded": expanded
    });

    return (
      <input
        autoComplete="off"
        className={className}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder="Type the name of a town/city…"
        spellCheck={false}
        ref={ref}
        type="search"
        value={value}
      />
    );
  }
);

RegionSelectInput.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default React.memo(RegionSelectInput);
