import React, { PureComponent } from "react";
import NumberTypes from "./NumberTypes";

class TypeStep extends PureComponent {
  handleChoose = type => {
    const form = this.el.closest("form");
    const input = document.createElement("input");

    input.name = "number_type";
    input.type = "hidden";
    input.value = type;

    form.appendChild(input);
    form.submit();
  };

  render() {
    return (
      <div
        ref={el => {
          this.el = el;
        }}
      >
        <NumberTypes active="" onChoose={this.handleChoose} />
      </div>
    );
  }
}

export default TypeStep;
