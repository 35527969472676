import React, { PureComponent } from "react";
import PropTypes from "prop-types";

function createText(html) {
  return {
    __html: html
  };
}

class DurationSummary extends PureComponent {
  constructor(props) {
    super(props);

    const { active } = this.props;

    this.state = {
      active
    };
  }

  componentDidMount() {
    const { active } = this.state;
    const { value } = document.querySelector(".js-duration-radio:checked");

    if (active !== value) {
      this.setState({
        active: value
      });
    }

    document.addEventListener("change", this.handleChange, false);
  }

  componentWillUnmount() {
    document.removeEventListener("change", this.handleChange, false);
  }

  handleChange = event => {
    const { target } = event;

    if (!target.matches(".js-duration-radio")) {
      return;
    }

    this.setState({
      active: target.value
    });
  };

  render() {
    const { active } = this.state;
    const { text } = this.props;

    return (
      <div className="duration-summary">
        <p>
          This costs just{" "}
          <span dangerouslySetInnerHTML={createText(text[active])} />
        </p>
      </div>
    );
  }
}

DurationSummary.propTypes = {
  active: PropTypes.oneOf(["annually", "monthly"]).isRequired,
  text: PropTypes.shape({
    annually: PropTypes.string.isRequired,
    monthly: PropTypes.string.isRequired
  }).isRequired
};

export default DurationSummary;
