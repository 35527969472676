import React from "react";
import times from "lodash/times";

function PhoneNumbersLoading() {
  return (
    <div className="phone-numbers-loading">
      <div className="phone-numbers-loading__content">
        <div className="phone-numbers">
          {times(12, String).map(index => (
            <div className="phone-numbers-loading__item" key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default PhoneNumbersLoading;
