import PropTypes from "prop-types";
import React from "react";
import FormErrors from "./FormErrors";
import CardForm from "./CardForm";

class NewCardForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      submitting: false
    };
  }

  createCard(paymentMethod, callback) {
    return new Promise((resolve, reject) => {
      fetch("/dashboard/cards", {
        body: JSON.stringify({
          payment_method: paymentMethod
        }),
        credentials: "same-origin",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "POST"
      })
        .then(response => {
          if (!response.ok) {
            throw new Error("Card creation failed");
          }

          return response.json();
        })
        .then(data => resolve(data))
        .catch(reject);
    });
  }

  handleClick = event => {
    event.preventDefault();
    const { onChooseMethod } = this.props;
    onChooseMethod("existing");
  };

  handleIntentBeforeSend = () => {
    this.setState({
      errors: [],
      submitting: true
    });
  };

  handleIntentSuccess = async setupIntent => {
    const { onCardCreate } = this.props;

    try {
      const card = await this.createCard(setupIntent.payment_method);
      onCardCreate(card);
    } catch (error) {
      console.error("failed to create card", error);
    }
  };

  handleIntentError = error => {
    console.error("There was an error", error);

    this.setState({
      errors: [error.message],
      submitting: false
    });
  };

  handleSubmit = event => {
    event.preventDefault();
  };

  render() {
    const { errors, submitting } = this.state;
    const { setupIntentClientSecret, hasExistingCard } = this.props;

    return (
      <form onSubmit={this.handleSubmit}>
        {errors.length > 0 && (
          <FormErrors
            errors={errors}
            title="There was an error adding your card…"
          />
        )}
        <CardForm
          clientSecret={setupIntentClientSecret}
          onIntentBeforeSend={this.handleIntentBeforeSend}
          onIntentError={this.handleIntentError}
          onIntentSuccess={this.handleIntentSuccess}
          submitting={submitting}
        />
        <div style={{ margin: "32px 0 0" }}>
          <input
            className="button button--disableable button--green button--medium"
            disabled={submitting}
            type="submit"
            value={submitting ? "Adding card" : "Add new card"}
          />
          {hasExistingCard && (
            <button
              className="button button--medium"
              onClick={this.handleClick}
              style={{ margin: "0 0 0 24px" }}
              type="button"
            >
              Cancel
            </button>
          )}
        </div>
      </form>
    );
  }
}

NewCardForm.propTypes = {
  hasExistingCard: PropTypes.bool.isRequired,
  onCardCreate: PropTypes.func.isRequired,
  onChooseMethod: PropTypes.func.isRequired,
  setupIntentClientSecret: PropTypes.string.isRequired
};

export default NewCardForm;
