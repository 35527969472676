import PropTypes from "prop-types";
import React from "react";
import NewCardForm from "./NewCardForm";
import PaymentCard from "./PaymentCard";

class PaymentSettings extends React.PureComponent {
  constructor(props) {
    super(props);

    const { card } = this.props;

    this.state = {
      card,
      screen: card ? "existing" : "create",
      succeeded: false
    };
  }

  handleCardCreate = card => {
    this.setState({
      card,
      screen: "existing",
      succeeded: true
    });
  };

  handleChooseMethod = method => {
    this.setState({
      screen: method
    });
  };

  render() {
    const { authenticityToken, setupIntentClientSecret } = this.props;
    const { card, screen, succeeded } = this.state;

    return screen === "existing" ? (
      <div>
        <PaymentCard {...card} />
        <div style={{ margin: "32px 0 0" }}>
          {succeeded ? (
            <p>Your new card details have been added.</p>
          ) : (
            <button
              className="button button--medium"
              onClick={() => this.setState({ screen: "create" })}
              type="button"
            >
              Use a different card
            </button>
          )}
        </div>
      </div>
    ) : (
      <NewCardForm
        authenticityToken={authenticityToken}
        hasExistingCard={!!card}
        onCardCreate={this.handleCardCreate}
        onChooseMethod={this.handleChooseMethod}
        setupIntentClientSecret={setupIntentClientSecret}
      />
    );
  }
}

PaymentSettings.defaultProps = {
  card: null
};

PaymentSettings.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  card: PropTypes.object,
  setupIntentClientSecret: PropTypes.string.isRequired
};

export default PaymentSettings;
