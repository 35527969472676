import PropTypes from "prop-types";
import React, { Fragment, PureComponent } from "react";

class ConfirmMobile extends PureComponent {
  render() {
    const { aboutCookies, allowCookies } = this.props;

    return (
      <Fragment>
        <div className="overlay u-max-z" />
        <div className="cookie-banner-mobile u-max-z">
          <h2 className="cookie-banner-mobile__title">We value your privacy</h2>
          <p className="cookie-banner-mobile__text">
            This website uses cookies in order to provide you with the most
            relevant information. Accept cookies for the best experience.
          </p>
          <div className="cookie-banner-mobile__buttons">
            <button
              className="button button--medium cookie-banner-mobile__button"
              onClick={() => allowCookies("all")}
              type="button"
            >
              Accept Cookies
            </button>
            <button
              className="button button--medium button--purple cookie-banner-mobile__button"
              onClick={aboutCookies}
              type="button"
            >
              Configure Cookies
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

ConfirmMobile.propTypes = {
  aboutCookies: PropTypes.func.isRequired,
  allowCookies: PropTypes.func.isRequired
};

export default ConfirmMobile;
