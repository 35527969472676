export function getScrollbarWidth() {
  const outer = document.createElement("div");

  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll";
  outer.style.msOverflowStyle = "scrollbar";

  document.body.appendChild(outer);

  const inner = document.createElement("div");

  outer.appendChild(inner);

  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}

export function pluralize(count, singular, plural) {
  return count === 1 ? `1 ${singular}` : `${count} ${plural}`;
}

// A useful wrapper that goes around a Promise to provide timeout functionality.
// This is especially useful to use in conjunction with the Fetch API as it provides
// no native support for specifying a maximum request timeouts.
export function timeout(ms, promise) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error("Timeout"));
    }, ms);

    promise.then(resolve, reject);
  });
}
