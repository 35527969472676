import React, { PureComponent } from "react";
import PropTypes from "prop-types";

function createText(html) {
  return {
    __html: html
  };
}

class CancellationReasonSummary extends PureComponent {
  constructor(props) {
    super(props);

    const { active } = this.props;

    this.state = {
      active
    };
  }

  componentDidMount() {
    document.addEventListener("change", this.handleChange, false);
  }

  componentWillUnmount() {
    document.removeEventListener("change", this.handleChange, false);
  }

  handleChange = event => {
    const { target } = event;

    if (!target.matches(".js-reason_type-radio")) {
      return;
    }

    this.setState({
      active: target.value
    });
  };

  render() {
    const { active } = this.state;
    const { text } = this.props;

    return (
      <div className="reason_type-summary">
        <p>
          This costs just{" "}
          <span dangerouslySetInnerHTML={createText(active)} />
        </p>
      </div>
    );
  }
}

CancellationReasonSummary.propTypes = {
  active: PropTypes.string,//PropTypes.oneOf(["annually", "monthly"]).isRequired,
  text: PropTypes.shape({
    annually: PropTypes.string.isRequired,
    monthly: PropTypes.string.isRequired
  }).isRequired
};

export default CancellationReasonSummary;
