import React, { PureComponent } from "react";
import HiddenInput from "./HiddenInput";
import PhoneNumbers from "./PhoneNumbers";
import RegionSelect from "./RegionSelect";

class ChooseLocalNumber extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedRegion: null
    };
  }

  handleSelect = selectedRegion => {
    this.setState({ selectedRegion });
  };

  render() {
    const { selectedRegion } = this.state;

    return (
      <React.Fragment>
        <HiddenInput
          name="prefix"
          value={selectedRegion ? selectedRegion.prefix : ""}
        />
        <HiddenInput
          name="location"
          value={selectedRegion ? selectedRegion.name : ""}
        />
        <div className="container container--narrow">
          <h2 className="u-visually-hidden">Choose a local number</h2>
          <div className="signup-number__select">
            <RegionSelect
              focusOnInitialMount
              regionSelected={this.handleSelect}
              selectedRegion={selectedRegion}
            />
          </div>
        </div>
        {selectedRegion !== null && (
          <div
            className="container"
            ref={numbers => {
              this.numbers = numbers;
            }}
          >
            <div className="signup-number__heading">
              <p className="sub-heading sub-heading--small">
                We have plenty of <strong>{selectedRegion.prefix}</strong>{" "}
                numbers for <strong>{selectedRegion.name}</strong>. Take your
                pick!
              </p>
            </div>
            <PhoneNumbers prefix={selectedRegion.prefix} />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default ChooseLocalNumber;
