import React, { useEffect, useRef } from "react";
import classnames from "classnames";

const Suggestion = React.memo(({ hovered, onClick, region }) => {
  const li = useRef(null);

  useEffect(() => {
    if (hovered) {
      li.current.scrollIntoViewIfNeeded();
    }
  }, [hovered]);

  return (
    <li
      className={classnames({
        "region-select__item": true,
        "region-select__item--hover": hovered
      })}
      ref={li}
    >
      <button
        className="region-select__item-button"
        onMouseDown={event => {
          event.preventDefault();
        }}
        onClick={() => onClick(region)}
      >
        {region.prefix} {region.name}
      </button>
    </li>
  );
});

function Suggestions({ hovered, onClick, regions }) {
  return (
    <ul className="region-select__list">
      {regions.map((region, index) => (
        <Suggestion
          key={region.prefix}
          hovered={hovered === index}
          onClick={onClick}
          region={region}
        />
      ))}
    </ul>
  );
}

export default React.memo(Suggestions);
