import PropTypes from "prop-types";
import React, { Fragment, PureComponent } from "react";

class About extends PureComponent {
  render() {
    const { allowCookies, editSettings } = this.props;

    return (
      <Fragment>
        <h2 className="cookie-banner__title">About cookies</h2>
        <div className="cookie-banner__content">
          <p className="cookie-banner__text">
            There are a number of cookies we need to use in order for our
            website to work properly. These cannot be disabled.
          </p>
          <p className="cookie-banner__text">
            However, you can disable non-essential cookies for the third-party
            services we use to help us provide better customer support, measure
            the performance of this website and run more effective marketing
            campaigns.
          </p>
        </div>
        <div className="cookie-banner__buttons">
          <button
            className="button button--medium"
            onClick={editSettings}
            type="button"
          >
            Edit Settings
          </button>
          <button
            className="cookie-banner__link-button"
            onClick={() => allowCookies("all")}
            type="button"
          >
            Allow third-party cookies
          </button>
        </div>
      </Fragment>
    );
  }
}

About.propTypes = {
  allowCookies: PropTypes.func.isRequired,
  editSettings: PropTypes.func.isRequired
};

export default About;
