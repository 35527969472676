import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import NumberContext from "../../contexts/NumberContext";
import PhoneNumber from "./PhoneNumber";
import PhoneNumbersLoading from "./PhoneNumbersLoading";
import { timeout } from "../../utils";

const availableNumbers = prefix => {
  return timeout(
    5000,
    fetch(`/numbers/available?prefix=${prefix}`, {
      headers: {
        Accepts: "application/json"
      }
    })
  ).then(response => response.json());
};

class PhoneNumbers extends PureComponent {
  constructor(props) {
    super(props);

    const { defaultNumbers } = this.props;

    this.state = {
      errors: [],
      loaded: defaultNumbers.length > 0,
      loading: defaultNumbers.length === 0,
      numbers: defaultNumbers
    };
  }

  componentDidMount() {
    const { loaded } = this.state;

    if (loaded) {
      return;
    }

    this.retrieve();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.prefix !== this.props.prefix && this.props.prefix) {
      this.retrieve();
    }
  }

  refresh = () => {
    this.retrieve();
  };

  render() {
    const { errors, loaded, loading, numbers } = this.state;

    if (errors.length > 0) {
      return this.renderErrors();
    }

    if (loading) {
      return <PhoneNumbersLoading />;
    }

    if (!loaded) {
      return null;
    }

    return numbers.length > 0 ? (
      <NumberContext.Consumer>
        {({ chosenNumber, numberChosen }) => {
          return (
            <React.Fragment>
              <ul className="phone-numbers">
                {numbers.map(number => {
                  return (
                    <PhoneNumber
                      key={number.number}
                      number={number}
                      numberChosen={numberChosen}
                      selected={
                        chosenNumber !== null &&
                        number.number === chosenNumber.number
                      }
                    />
                  );
                })}
              </ul>
              <p className="refresh-phone-numbers">
                Don’t like any of these?{" "}
                <button
                  className="refresh-phone-numbers__button"
                  onClick={this.refresh}
                  type="button"
                >
                  Load more numbers
                </button>
                .
              </p>
            </React.Fragment>
          );
        }}
      </NumberContext.Consumer>
    ) : (
      <p>No numbers found for {prefix}</p>
    );
  }

  renderErrors() {
    const { errors } = this.state;

    return (
      <ul>
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    );
  }

  retrieve = () => {
    const { prefix } = this.props;

    this.setState({
      errors: [],
      loaded: false,
      loading: true,
      numbers: []
    });

    availableNumbers(prefix)
      .then(({ numbers }) => {
        this.setState({
          loaded: true,
          loading: false,
          numbers
        });
      })
      .catch(error => {
        this.setState({
          errors: ["Could not load any numbers!"],
          loaded: true,
          loading: false
        });
      });
  };
}

PhoneNumbers.defaultProps = {
  defaultNumbers: []
};

PhoneNumbers.propTypes = {
  defaultNumbers: PropTypes.array,
  prefix: PropTypes.string.isRequired
};

export default PhoneNumbers;
