import { default as Fuze } from "fuse.js";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import RegionSelectInput from "./RegionSelectInput";
import Spinner from "./Spinner";
import Suggestions from "./Suggestions";
import { timeout } from "../../utils";

const fuzeOptions = Object.freeze({
  keys: ["name", "prefix"],
  threshold: 0.2
});

class RegionSelect extends PureComponent {
  constructor(props) {
    super(props);

    const { selectedRegion } = this.props;

    this.state = {
      filtered: [],
      focus: false,
      fuze: new Fuze([], fuzeOptions),
      hovered: false,
      loaded: false,
      region: selectedRegion || null,
      regions: [],
      value: ""
    };
  }

  componentDidMount() {
    const { filtered, region } = this.state;
    const { focusOnInitialMount } = this.props;

    filtered.length === 1 && this.setState({ hovered: 0 });
    !region && focusOnInitialMount && this.input && this.input.focus();

    timeout(
      5000,
      fetch("/numbers/prefixes", {
        headers: {
          Accepts: "application/json"
        }
      })
        .then(r => r.json())
        .then(({ prefixes }) => {
          this.setState({
            fuze: new Fuze(prefixes, fuzeOptions),
            loaded: true,
            regions: prefixes
          });
        })
        .catch(error => {
          // TODO: Handle this error gracefully
          alert("Could not fetch a list of prefixes", error);
        })
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { region } = this.state;

    if (!prevState.region !== region) {
      this.props.regionSelected(region);
    }
  }

  decrement = () => {
    const { filtered, hovered } = this.state;

    this.setState({
      hovered:
        typeof hovered === "number"
          ? (hovered + filtered.length - 1) % filtered.length
          : 0
    });
  };

  filter = value => {
    const { fuze } = this.state;
    return fuze.search(value);
  };

  increment = () => {
    const { filtered, hovered } = this.state;

    this.setState({
      hovered: typeof hovered === "number" ? (hovered + 1) % filtered.length : 0
    });
  };

  select = region => {
    this.setState({
      filtered: [],
      focus: false,
      hovered: null,
      region,
      value: ""
    });
  };

  handleBlur = () => {
    this.setState({
      focus: false,
      hovered: null
    });
  };

  handleChange = event => {
    const { value } = event.target;

    this.setState({
      filtered: this.filter(value),
      hovered: null,
      value
    });
  };

  handleClick = region => {
    this.select(region);
  };

  handleFocus = () => {
    this.setState({
      focus: true
    });
  };

  handleKeyDown = event => {
    switch (event.key) {
      case "ArrowUp":
        event.preventDefault();
        return this.decrement();

      case "ArrowDown":
        event.preventDefault();
        return this.increment();

      case "Enter":
        const { filtered, hovered } = this.state;
        return hovered !== null && this.handleClick(filtered[hovered]);

      default:
        return;
    }
  };

  render() {
    const { allowRegionChange } = this.props;
    const { filtered, focus, hovered, region, value } = this.state;

    const expanded = focus && !region && filtered.length > 0;

    return (
      <div className="region-select">
        {region === null ? (
          this.renderSelect()
        ) : (
          <React.Fragment>
            <p className="input input--large region-select__selected">
              {region.name}
            </p>
            {allowRegionChange ? (
              <button
                className="button region-select__button"
                onClick={() => this.setState({ region: null })}
              >
                Choose a different region
              </button>
            ) : (
              <div className="region-select__spinner">
                <Spinner />
              </div>
            )}
          </React.Fragment>
        )}

        {expanded && (
          <Suggestions
            hovered={hovered}
            onClick={this.handleClick}
            regions={filtered}
          />
        )}
      </div>
    );
  }

  renderSelect() {
    const { filtered, focus, region, value } = this.state;
    const expanded = focus && !region && filtered.length > 0;

    return (
      <RegionSelectInput
        expanded={expanded}
        onBlur={this.handleBlur}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onKeyDown={this.handleKeyDown}
        ref={input => {
          this.input = input;
        }}
        value={value}
      />
    );
  }
}

RegionSelect.defaultProps = {
  allowRegionChange: true,
  focusOnInitialMount: false,
  selectedRegion: null
};

RegionSelect.propTypes = {
  allowRegionChange: PropTypes.bool,
  focusOnInitialMount: PropTypes.bool,
  regionSelected: PropTypes.func.isRequired,
  selectedRegion: PropTypes.object
};

export default RegionSelect;
