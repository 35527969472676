import PropTypes from "prop-types";
import React from "react";
import FormRow from "./FormRow";
import FormRowColumn from "./FormRowColumn";
import HiddenLabel from "./HiddenLabel";

const stripeElementOptions = Object.freeze({
  classes: {
    base: "input input--large"
  },
  style: {
    base: {
      color: "#32325d",
      fontFamily: "'Brandon Text', system-ui, sans-serif",
      fontSize: "18px",
      fontSmoothing: "antialiased",
      textAlign: "center",
      "::placeholder": {
        color: "#8e8eaa"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
});

class CardForm extends React.PureComponent {
  componentDidMount() {
    this.form = this.container.closest("form");

    if (this.form) {
      this.form.addEventListener("submit", this.handleSubmit, false);
    }

    this.setup();
  }

  componentWillUnmount() {
    if (this.elements) {
      this.teardown();
    }

    if (this.form) {
      this.form.removeEventListener("submit", this.handleSubmit, false);
    }
  }

  handleSubmit = event => {
    const { clientSecret, onIntentBeforeSend, submitting } = this.props;

    if (submitting) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    onIntentBeforeSend();

    this.stripe
      .handleCardSetup(clientSecret, this.elements.cardNumber, {
        payment_method_data: {
          billing_details: {
            name: this.cardholderName.value
          }
        }
      })
      .then(this.handleResult)
      .catch(this.handleError);
  };

  handleError = error => {
    const { onIntentError } = this.props;
    onIntentError(error);
  };

  handleResult = result => {
    const { error, setupIntent } = result;
    const { onIntentSuccess } = this.props;

    if (error) {
      return this.handleError(error);
    }

    onIntentSuccess(setupIntent);
  };

  render() {
    return (
      <div
        ref={container => {
          this.container = container;
        }}
      >
        <FormRow>
          <FormRowColumn>
            <HiddenLabel htmlFor="cardholder_name">
              Cardholder's Name
            </HiddenLabel>
            <input
              className="input input--large"
              id="cardholder_name"
              name="cardholder_name"
              placeholder="Cardholder’s Name"
              ref={el => {
                this.cardholderName = el;
              }}
              spellCheck="false"
              type="text"
            />
          </FormRowColumn>
        </FormRow>
        <FormRow>
          <FormRowColumn>
            <div
              ref={node => {
                this.cardNumber = node;
              }}
            />
          </FormRowColumn>
        </FormRow>
        <FormRow modifier="2c">
          <FormRowColumn>
            <div
              ref={node => {
                this.cardExpiry = node;
              }}
            />
          </FormRowColumn>
          <FormRowColumn>
            <div
              ref={node => {
                this.cardCvc = node;
              }}
            />
          </FormRowColumn>
        </FormRow>
      </div>
    );
  }

  setup() {
    const pk = document.querySelector(".js-stripe-pk");

    window.stripe = this.stripe = Stripe(pk.getAttribute("content"));

    const elements = this.stripe.elements({
      fonts: [
        {
          family: "Brandon Text",
          src: `local("Brandon Text"), local("brandon-text"), url("https://cdn.landlineanywhere.uk/fonts/BrandonText-Regular.woff2") format("truetype")`
        }
      ]
    });

    const placeholders = {
      cardCvc: "Security Code",
      cardNumber: "The long number on your card"
    };

    this.elements = ["cardCvc", "cardExpiry", "cardNumber"].reduce(
      (object, key) => ({
        ...object,
        [key]: elements.create(key, {
          ...stripeElementOptions,
          placeholder: placeholders[key]
        })
      }),
      {}
    );

    Object.entries(this.elements).forEach(([key, element]) => {
      element.mount(this[key]);
    });
  }

  teardown() {
    Object.entries(this.elements).forEach(([key, element]) => {
      element.unmount(this[key]);
    });
  }
}

CardForm.defaultProps = {
  nameAttributeForCardholderField: "cardholder_name"
};

CardForm.propTypes = {
  clientSecret: PropTypes.string.isRequired,
  nameAttributeForCardholderField: PropTypes.string,
  onIntentBeforeSend: PropTypes.func.isRequired,
  onIntentError: PropTypes.func.isRequired,
  onIntentSuccess: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default CardForm;
