import React from "react";
import PhoneNumbers from "./PhoneNumbers";

function ChooseNationalNumber() {
  return (
    <div className="container">
      <h2 className="u-visually-hidden">Choose a national number</h2>
      <PhoneNumbers prefix="0330" />
    </div>
  );
}

export default ChooseNationalNumber;
