import PropTypes from "prop-types";
import React from "react";

function HiddenLabel({ children, htmlFor }) {
  return (
    <label className="u-visually-hidden" htmlFor={htmlFor}>
      {children}
    </label>
  );
}

HiddenLabel.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string.isRequired
};

export default React.memo(HiddenLabel);
