import PropTypes from "prop-types";
import React from "react";
import PurchaseCallBundleDialog from "./PurchaseCallBundleDialog";

class PurchaseCallBundleButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const { open } = this.state;
    const { authenticityToken, callBundle } = this.props;

    const onClick = open ? this.handleClose : this.handleOpen;

    return (
      <div>
        <button
          className="button button--medium-small button--navy call-bundle__add-button"
          onClick={onClick}
        >
          Purchase this bundle
        </button>
        {open && (
          <PurchaseCallBundleDialog
            callBundle={callBundle}
            onClose={this.handleClose}
            authenticityToken={authenticityToken}
          />
        )}
      </div>
    );
  }
}

PurchaseCallBundleButton.propTypes = {
  callBundle: PropTypes.shape({
    name: PropTypes.string.isRequired,
    includedMinutes: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired
  }).isRequired,
  authenticityToken: PropTypes.string.isRequired
};

export default React.memo(PurchaseCallBundleButton);
