import PropTypes from "prop-types";
import React from "react";
import { getScrollbarWidth } from "../../utils";

class NumberConfirmationDialog extends React.PureComponent {
  componentDidMount() {
    const { scrollY } = window;
    const height = window.innerHeight + scrollY;
    const scrollbarWidth = getScrollbarWidth();

    this.scrollPosition = scrollY;

    document.body.classList.add("is-dialog-open");

    document.body.style.height = `${height}px`;
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    document.body.style.top = `-${scrollY}px`;

    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.body.classList.remove("is-dialog-open");

    document.body.style.height = "";
    document.body.style.paddingRight = "";
    document.body.style.top = "";

    window.scrollTo(0, this.scrollPosition);

    document.removeEventListener("keydown", this.handleKeyDown, false);
  }

  handleKeyDown = event => {
    const { onCancel, onConfirm } = this.props;

    if (event.key === "Escape") {
      event.preventDefault();
      onCancel();
    } else if (event.key === "Enter") {
      event.preventDefault();
      onConfirm();
    }
  };

  render() {
    const { number, onCancel, onConfirm, pricePerMonth, includedMinutes } = this.props;

    return (
      <React.Fragment>
        <div className="overlay" onClick={onCancel} />
        <div className="dialog">
          <div className="number-confirmation-dialog">
            <h2 className="dialog__title">The number you’ve chosen is…</h2>
            <p className="number-confirmation-dialog__number">
              {number.number}
            </p>
            <p className="dialog__text">
              This number will cost just £{pricePerMonth}/month and includes {includedMinutes} minutes of calls to your mobile or landline.
            </p>
            <div className="number-confirmation-dialog__buttons">
              <button
                className="button button--green button--medium number-confirmation-dialog__button"
                onClick={onConfirm}
                type="button"
              >
                Set up this number
              </button>
              <button
                className="button button--medium button--purple number-confirmation-dialog__button"
                onClick={onCancel}
                type="button"
              >
                Choose another
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

NumberConfirmationDialog.propTypes = {
  number: PropTypes.shape({
    band: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  pricePerMonth: PropTypes.number.isRequired,
  includedMinutes: PropTypes.number.isRequired
};

export default NumberConfirmationDialog;
