import PropTypes from "prop-types";
import React, { Fragment, PureComponent } from "react";

class Edit extends PureComponent {
  state = {
    index: 0,
    purposes: [
      {
        consent: null,
        identifier: "analytics",
        subtitle:
          "We use Google Analytics to measure the performance of our website. We do not store any personal data and your IP address is anonymised.",
        title: "Analytics cookies"
      },
      {
        consent: null,
        identifier: "chat",
        subtitle:
          "We use a live chat service called Natterly so we can privide support to you where available. Various cookies are stored so chats remain active when you change page.",
        title: "Live chat cookies"
      },
      {
        consent: null,
        identifier: "tracking",
        subtitle:
          "We use various tracking cookies to help us measure the performance of our marketing campaigns and show you content and promotions we think you’d be interested in.",
        title: "Tracking cookies"
      }
    ]
  };

  componentDidUpdate() {
    const { allowCookies } = this.props;
    const { index, purposes } = this.state;

    if (index !== purposes.length) {
      return;
    }

    const allowed = purposes
      .filter(({ consent }) => consent === "allow")
      .map(({ identifier }) => identifier);

    allowCookies(allowed);
  }

  render() {
    const { index, purposes } = this.state;
    const purpose = purposes[index];

    if (!purpose) {
      return null;
    }

    const { identifier, subtitle, title } = purpose;

    return (
      <Fragment>
        <h2 className="cookie-banner__title">{title}</h2>
        <div className="cookie-banner__content">
          <p className="cookie-banner__text">{subtitle}</p>
        </div>
        <div className="cookie-banner__buttons">
          <button
            className="button button--medium"
            type="button"
            onClick={() => this.update(identifier, "allow")}
          >
            Yes
          </button>
          <button
            className="button button--medium"
            type="button"
            onClick={() => this.update(identifier, "deny")}
          >
            No
          </button>
        </div>
      </Fragment>
    );
  }

  update = (identifier, consent) => {
    const { index, purposes } = this.state;

    this.setState({
      index: index + 1,
      purposes: purposes.map(purpose => {
        return purpose.identifier === identifier
          ? {
              ...purpose,
              consent
            }
          : purpose;
      })
    });
  };
}

Edit.propTypes = {
  allowCookies: PropTypes.func.isRequired
};

export default Edit;
