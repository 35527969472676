import PropTypes from "prop-types";
import React from "react";
import { getScrollbarWidth } from "../../utils";
import Spinner from "./Spinner";
import CardForm from "./CardForm";
import FormErrors from "./FormErrors";
import ReactPolling from "react-polling";

function createErrorMessage(message) {
  return [
    message,
    "Please try again or get in touch with our support team if this issue persists."
  ].filter(n => n).join(" ");
}

class PurchaseCallBundleDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      submitting: false,
      paymentIntent: null,
      paymentMethod: null,
      paymentAttempt: null,
      setupIntent: null,
      requiresAction: false,
      requiresPaymentMethod: false,
      purchaseComplete: false,
      bundleApplied: false,

      // cardForm
      creatingCard: false,
      cardCreationError: null,
    };

  }

  componentDidMount() {
    const { scrollY } = window;
    const height = window.innerHeight + scrollY;
    const scrollbarWidth = getScrollbarWidth();

    this.scrollPosition = scrollY;

    document.body.classList.add("is-dialog-open");

    document.body.style.height = `${height}px`;
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    document.body.style.top = `-${scrollY}px`;

    document.addEventListener("keydown", this.handleKeyDown, false);

    const pk = document.querySelector(".js-stripe-pk");
    this.stripe = window.stripe = window.stripe || Stripe(pk.getAttribute("content"));
  }

  componentWillUnmount() {
    document.body.classList.remove("is-dialog-open");

    document.body.style.height = "";
    document.body.style.paddingRight = "";
    document.body.style.top = "";

    window.scrollTo(0, this.scrollPosition);

    document.removeEventListener("keydown", this.handleKeyDown, false);
  }

  handleKeyDown = event => {
    const { onClose } = this.props;

    if (event.key === "Escape") {
      event.preventDefault();
      onClose();
    }
  };

  handleSubmit = () => {
    const { submitting, requiresAction } = this.state;

    if (submitting || requiresAction) {
      return;
    }

    this.setState(
      {
        error: "",
        submitting: true
      },
      () => this.startRequest()
    );
  };

  startRequest = () => {
      this.fetchStripeInvoice()
        .then(this.handleStripeInvoice)
        .then(this.retrievePaymentIntent)
        .then(this.handlePaymentIntent)
        .catch(this.handleError);
  };

  fetchStripeInvoice = () => {
    const { authenticityToken, callBundle } = this.props;

    return fetch("/dashboard/minutes", {
      body: JSON.stringify({
        _authenticity_token: authenticityToken,
        call_bundle_name: callBundle.name,
      }),
      credentials: "same-origin",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      method: "POST"
    })
      .then(response => response.json());
  }

  handleStripeInvoice = response => {
    if (response.payment_intent && response.payment_attempt) {
      return this.setState({ paymentIntent: response.payment_intent, paymentAttempt: response.payment_attempt });
    }

    return Promise.reject(createErrorMessage("An error occurred whilst fetching your invoice."));
  }

  retrievePaymentIntent = () => {
    const { paymentIntent } = this.state;

    return this.stripe.retrievePaymentIntent(paymentIntent.client_secret);
  }

  handlePaymentIntent = (response) => {
    switch (response.paymentIntent.status) {
      case "succeeded":
        return this.setState({ purchaseComplete: true, requiresAction: false, requiresPaymentMethod: false });
      case "requires_action", "requires_confirmation":
        this.setState({ requiresAction: true },);
        return this.confirmCardPayment();
      case "requires_payment_method":
        return this.handleRequiresPaymentMethod();
      default:
        return Promise.reject(createErrorMessage("An unknown error occurred while processing your request."));
    }
  }

  confirmCardPayment = () => {
    const { paymentIntent, paymentMethod } = this.state;

    return this.stripe.confirmCardPayment(paymentIntent.client_secret, { payment_method: paymentMethod })
      .then(this.handleStripeActionResponse)
      .then(this.retrievePaymentIntent)
      .then(this.handlePaymentIntent)
      .catch(this.handleError);
  }

  handleStripeActionResponse = result => {
    if (result.paymentIntent) {
      return Promise.resolve();
    } else if(result.error) {
      if (result.error.message) {
        return this.setState({ error: result.error.message });
      } else {
        return this.setState({ error: result.error });
      }
    }
  }

  handleRequiresPaymentMethod = () => {
    this.fetchSetupIntent()
      .then(this.handleFetchSetupIntent);
  }

  fetchSetupIntent = () => {
    return fetch("/dashboard/minutes/setup_intent", {
      credentials: "same-origin",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      method: "GET"
    })
      .then(response => response.json())
  }

  handleFetchSetupIntent = response => {
    return this.setState({ setupIntent: response.setup_intent, requiresPaymentMethod: true });
  }

  handleError = error => {
    this.setState({
      error: error,
      submitting: false,
      requiresAction: false
    });
  };

  handleSetupIntentBeforeSend = () => {
    this.setState({ creatingCard: true, error: null, cardCreationError: null });
  };

  handleSetupIntentError = error => {
    const cardCreationError =
      (error && error.message) ||
      "An unknown error occurred. Please try again.";

    this.setState({
      cardCreationError,
      creatingCard: false,
      error: cardCreationError
    });
  };

  handleSetupIntentSuccess = setupIntent => {
    const { payment_method: paymentMethod } = setupIntent;

    this.setState(
      {
        cardCreationError: null,
        creatingCard: false,
        paymentMethod,
      },
      this.confirmCardPayment
    );
  };

  handleCardFormSubmit = event => {
    event.preventDefault();
  };

  render() {
    const { purchaseComplete, bundleApplied, requiresPaymentMethod } = this.state;

    return (
      <React.Fragment>
        {purchaseComplete === false && requiresPaymentMethod == false                             && this.renderPurchase()}
        {purchaseComplete === false && requiresPaymentMethod == true                              && this.renderCardForm()}
        {purchaseComplete === true  && requiresPaymentMethod == false && bundleApplied === false  && this.renderWait()}
        {purchaseComplete === true  && requiresPaymentMethod == false && bundleApplied === true   && this.renderBundleApplied()}
      </React.Fragment>
    );
  }

  renderPurchase() {
    const { callBundle, onClose } = this.props;
    const { error, submitting, requiresAction } = this.state;
    const formattedIncludedMinutes = callBundle.includedMinutes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
      <React.Fragment>
        <div className="overlay" onClick={onClose} />
        <div className="dialog">
          <div className="bundle-dialog">
            <h2 className="dialog__title">The bundle you’ve chosen is…</h2>
            <p className="bundle-dialog__number">
              {callBundle.name}
            </p>
            <p className="dialog__text">
              This bundle will cost just £{callBundle.price} and includes {formattedIncludedMinutes} minutes of calls to your mobile or landline.
            </p>
            {error.length > 0 && <p className="error">{error}</p>}

            <div className="bundle-dialog__buttons">
              <button
                className="button button--green button--medium bundle-dialog__button button--disableable"
                disabled={submitting || requiresAction}
                onClick={this.handleSubmit}
                type="button"
              >
                Purchase this bundle
              </button>
              <button
                className="button button--medium button--purple bundle-dialog__button"
                onClick={onClose}
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderCardFormErrors() {
    const { cardCreationError, error } = this.state;

    const errors = cardCreationError
      ? [cardCreationError]
      : [error];

    const title = cardCreationError
      ? "There was a problem adding that card"
      : "We weren’t able to take payment using your card";

    return <FormErrors errors={errors} title={title} />;
  }

  renderCardForm() {
    const { creatingCard, error, setupIntent } = this.state;
    const { onClose } = this.props;

    return (
      <React.Fragment>
        <div className="overlay" onClick={onClose} />
        <div className="dialog">
          <div className="bundle-dialog">
            <form onSubmit={this.handleCardFormSubmit}>
              {error !== null && this.renderCardFormErrors()}
              <div className="bundle-dialog__form">
                <CardForm
                  clientSecret={setupIntent.client_secret}
                  onIntentBeforeSend={this.handleSetupIntentBeforeSend}
                  onIntentError={this.handleSetupIntentError}
                  onIntentSuccess={this.handleSetupIntentSuccess}
                  submitting={creatingCard}
                />
              </div>
              <div className="bundle-dialog__buttons">
                <input
                  className="button button--green button--medium bundle-dialog__button button--disableable"
                  disabled={creatingCard}
                  type="submit"
                  value="Pay using new card"
                />
                <button
                  className="button button--medium button--purple bundle-dialog__button"
                  onClick={onClose}
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderWait() {
    const { onClose } = this.props;
    const { paymentAttempt } = this.state;

    return (
      <React.Fragment>
        <div className="overlay" onClick={onClose} />
        <div className="dialog">
          <div className="bundle-dialog">
            <ReactPolling
              url={"/dashboard/minutes/verify_payment_attempt?payment_attempt_id=" + paymentAttempt.id}
              interval={1500}
              retryCount={24}
              method={"POST"}
              onSuccess={resp => {
                if (resp.payment_attempt && resp.payment_attempt.status === 'succeeded') {
                  this.setState({ bundleApplied: true });
                  return false;
                }
                return true;
              }}
              render={({ _startPolling, _stopPolling, isPolling }) => {
                if (isPolling) {
                  return (
                    <React.Fragment>
                      <h2 className="dialog__title">We're just applying your minutes…</h2>
                      <p className="dialog__text">It shouldn’t take us more than a few minutes.</p>
                      <div className="bundle-dialog__buttons">
                        <Spinner />
                      </div>
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment>
                    <h2 className="dialog__title">Oh no, something went wrong…</h2>
                    <p className="dialog__text">We've been unable to apply your minutes, please get in touch with our support team.</p>
                  </React.Fragment>
                  );
                }
              }}
            />

          </div>
        </div>
      </React.Fragment>
    );
  }

  renderBundleApplied() {
    const redirect = () => { window.location.href = "/dashboard/minutes";  }

    return (
      <React.Fragment>
        <div className="overlay" onClick={redirect} />
        <div className="dialog">
          <div className="bundle-dialog">
            <h2 className="dialog__title">Your minutes have been applied!</h2>
            <p className="dialog__text">
              Your minutes have been applied to your account and will be available to use immediately.
            </p>
            <div className="bundle-dialog__buttons">
              <button
                className="button button--medium button--purple bundle-dialog__button"
                onClick={redirect}
                type="button"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

PurchaseCallBundleDialog.propTypes = {
  callBundle: PropTypes.shape({
    name: PropTypes.string.isRequired,
    includedMinutes: PropTypes.number.isRequired,
    price: PropTypes.number.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  authenticityToken: PropTypes.string.isRequired,
};

export default PurchaseCallBundleDialog;
