import React, { PureComponent } from "react";
import CardForm from "./CardForm";
import FormErrors from "./FormErrors";
import Spinner from "./Spinner";

function createErrorMessage(message) {
  return [
    message,
    "Please try again or get in touch if you’re unable to activate your account."
  ].join(" ");
}

class ActivateButton extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cardCreationError: null,
      creatingCard: false,
      error: null,
      paymentIntentClientSecret: null,
      requiresAction: false,
      setupIntentClientSecret: null,
      status: null,
      submitting: false
    };
  }

  handleClick = event => {
    event.preventDefault();
    this.submit();
  };

  handleError = error => {
    this.setState({
      error: createErrorMessage("An unknown error occurred during activation.")
    });
  };

  componentDidMount() {
    const pk = document.querySelector(".js-stripe-pk");
    this.stripe = window.stripe =
      window.stripe || Stripe(pk.getAttribute("content"));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.status !== "requires_action" &&
      this.state.status === "requires_action"
    ) {
      this.stripe
        .handleCardPayment(this.state.paymentIntentClientSecret)
        .then(result => {
          this.setState(
            {
              submitting: false,
              status: null
            },
            () => this.submit()
          );
        })
        .catch(this.handleError);
    }
  }

  handleIntentBeforeSend = () => {
    this.setState({
      cardCreationError: null,
      creatingCard: true,
      error: null
    });
  };

  handleIntentError = error => {
    const cardCreationError =
      (error && error.message) ||
      "An unknown error occurred. Please try again.";

    this.setState({
      cardCreationError,
      creatingCard: false
    });
  };

  handleIntentSuccess = setupIntent => {
    const { payment_method: paymentMethod } = setupIntent;

    this.setState(
      {
        cardCreationError: null,
        creatingCard: false,
        paymentMethod
      },
      this.submit
    );
  };

  handleSubmit = event => {
    event.preventDefault();
  };

  render() {
    const { requiresAction, status, submitting } = this.state;

    if (requiresAction || submitting) {
      return this.renderSpinner();
    }

    return (
      <React.Fragment>
        {status === null && this.renderActivate()}
        {status === "requires_payment_method" && this.renderCardForm()}
      </React.Fragment>
    );
  }

  renderActivate() {
    const { error, submitting } = this.state;

    return (
      <React.Fragment>
        {error !== null && (
          <FormErrors
            errors={[error]}
            title="There was a problem activating your account…"
          />
        )}
        <div style={{ textAlign: "center" }}>
          <button
            className="button button--disableable button--large"
            disabled={submitting}
            onClick={this.handleClick}
            type="button"
          >
            Get started &mdash; buy my number now!
          </button>
        </div>
      </React.Fragment>
    );
  }

  renderCardForm() {
    const { creatingCard, error, setupIntentClientSecret } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        {error !== null && this.renderCardFormErrors()}
        <div className="form">
          <CardForm
            clientSecret={setupIntentClientSecret}
            onIntentBeforeSend={this.handleIntentBeforeSend}
            onIntentError={this.handleIntentError}
            onIntentSuccess={this.handleIntentSuccess}
            submitting={creatingCard}
          />
        </div>
        <div className="activate-button-container">
          <input
            className="button button--disableable button--large"
            disabled={creatingCard}
            type="submit"
            value="Add new card"
          />
        </div>
      </form>
    );
  }

  renderCardFormErrors() {
    const { cardCreationError, error } = this.state;

    const errors = cardCreationError
      ? [cardCreationError]
      : [`${error} Please add a different card.`];

    const title = cardCreationError
      ? "There was a problem adding that card…"
      : "We weren’t able to take payment using your card…";

    return <FormErrors errors={errors} title={title} />;
  }

  renderSpinner() {
    return (
      <div className="activate-spinner-container">
        <Spinner />
      </div>
    );
  }

  submit = () => {
    const { paymentMethod } = this.state;
    const terms = document.querySelector('input[name="terms"]').checked;

    if (!terms) {
      return alert(
        "You need to agree to the terms of service and privacy policy to use Landline Anywhere."
      );
    }

    const data = {
      continue: "1",
      newsletter: Number(
        document.querySelector('input[name="newsletter"]').checked
      ).toString(),
      terms: Number(terms).toString()
    };

    if (paymentMethod) {
      data.payment_method = paymentMethod;
    }

    this.setState(
      {
        error: null,
        status: null,
        submitting: true
      },
      () => {
        fetch("/signup/terms", {
          body: JSON.stringify(data),
          credentials: "same-origin",
          headers: {
            Accepts: "application/json",
            "Content-Type": "application/json"
          },
          method: "POST"
        })
          .then(response => {
            if (!response.ok) {
              throw new Error("Unexpected response from the server.");
            }

            return response.json();
          })
          .then(data => {
            switch (data.status) {
              case "requires_action":
                return this.setState({
                  paymentIntentClientSecret: data.payment_intent_client_secret,
                  paymentMethod: null,
                  status: "requires_action",
                  requiresAction: true,
                  submitting: false
                });

              case "requires_payment_method":
                return this.setState({
                  error: data.error,
                  paymentMethod: null,
                  requiresAction: false,
                  setupIntentClientSecret: data.setup_intent_client_secret,
                  status: "requires_payment_method",
                  submitting: false
                });

              case "success":
                return (window.location = "/signup/finished");
            }
          })
          .catch(error => {
            this.setState({
              submitting: false
            });

            alert("There was an problem. Please try again.");
          });
      }
    );
  };
}

export default ActivateButton;
