import React from "react";
import amex from "./icons/amex.svg";
import maestro from "./icons/maestro.svg";
import mastercard from "./icons/mastercard.svg";
import microchip from "./icons/microchip.svg";
import visa from "./icons/visa.svg";

const icons = Object.freeze({
  amex,
  maestro,
  mastercard,
  visa
});

function PaymentCard({ expires, number, type }) {
  return (
    <div className={`payment-card payment-card--${type}`}>
      <div className="payment-card__header">
        {type !== "unknown" && (
          <img className="payment-card__logo" src={icons[type]} />
        )}
      </div>
      <div className="payment-card__content">
        <div className="payment-card__microchip">
          <img className="payment-card__microchip-icon" src={microchip} />
        </div>
        <div className="payment-card__number">{number}</div>
        <div className="payment-card__dates">
          <dl className="payment-card__date">
            <dt className="payment-card__date-title">Expires</dt>
            <dd className="payment-card__date-value">{expires}</dd>
          </dl>
        </div>
      </div>
    </div>
  );
}

export default React.memo(PaymentCard);
