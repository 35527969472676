import PropTypes from "prop-types";
import React from "react";
import { getScrollbarWidth } from "../../utils";

class ChangeForwardingPopout extends React.PureComponent {
  state = {};

  static getDerivedStateFromProps(props, state) {
    const prevProps = state.prevProps || {};

    const number =
      prevProps.number !== props.number ? props.number : state.number;

    return {
      number,
      prevProps: props
    };
  }

  componentDidMount() {
    const { scrollY } = window;
    const height = window.innerHeight + scrollY;
    const scrollbarWidth = getScrollbarWidth();

    this.scrollPosition = scrollY;

    document.body.classList.add("is-dialog-open");

    document.body.style.height = `${height}px`;
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    document.body.style.top = `-${scrollY}px`;

    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.body.classList.remove("is-dialog-open");

    document.body.style.height = "";
    document.body.style.paddingRight = "";
    document.body.style.top = "";

    window.scrollTo(0, this.scrollPosition);

    document.removeEventListener("keydown", this.handleKeyDown, false);
  }

  handleChange = event => {
    this.setState({
      number: event.target.value
    });
  };

  handleSubmit = event => {
    const { onSubmit } = this.props;
    const { value } = this.input;

    event.preventDefault();

    onSubmit(value);
  };

  render() {
    const { number } = this.state;
    const { error, onClose, submitting } = this.props;

    return (
      <React.Fragment>
        <div className="overlay" onClick={onClose} />
        <div className="dialog">
          <div className="change-forwarding-popout">
            <form onSubmit={this.handleSubmit}>
              <p className="dialog__title">
                Change where this number forwards to by updating it below.
              </p>
              <p className="dialog__text">
                These changes shouldn’t take longer than about a minute to
                apply.
              </p>
              {error.length > 0 && <p className="error">{error}</p>}
              <div className="change-forwarding-popout__control">
                <input
                  className="input input--large change-forwarding-popout__input"
                  name="destination_number"
                  onChange={this.handleChange}
                  ref={input => {
                    this.input = input;
                  }}
                  type="tel"
                  value={number}
                />
                <input
                  className="button button--disableable button--green button--large"
                  disabled={number.length === 0 || submitting}
                  type="submit"
                  value="Update"
                />
              </div>
              <button
                className="button button--large button--purple change-forwarding-popout__cancel"
                onClick={onClose}
                type="button"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ChangeForwardingPopout.propTypes = {
  error: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default ChangeForwardingPopout;
