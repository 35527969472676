import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import NumberConfirmationDialog from "./NumberConfirmationDialog";
import NumberContext from "../../contexts/NumberContext";
import PhoneNumbers from "./PhoneNumbers";

function RegionPhoneNumbers({ numbers, prefix, pricePerMonth, includedMinutes }) {
  const [chosenNumber, setChosenNumber] = useState(null);

  const numberChosen = useCallback(
    number => {
      setChosenNumber(number);
    },
    [setChosenNumber]
  );

  const handleCancel = useCallback(() => {
    setChosenNumber(null);
  }, [setChosenNumber]);

  const handleConfirm = useCallback(() => {
    const { token } = chosenNumber;
    const encodedToken = encodeURIComponent(token);
    window.location = `/signup/start?number=${encodedToken}`;
  }, [chosenNumber]);

  return (
    <React.Fragment>
      <NumberContext.Provider value={{ chosenNumber, numberChosen }}>
        <PhoneNumbers defaultNumbers={numbers} prefix={prefix} />
      </NumberContext.Provider>
      {chosenNumber !== null && (
        <NumberConfirmationDialog
          number={chosenNumber}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          pricePerMonth={pricePerMonth}
          includedMinutes={includedMinutes}
        />
      )}
    </React.Fragment>
  );
}

RegionPhoneNumbers.propTypes = {
  numbers: PropTypes.array.isRequired,
  prefix: PropTypes.string.isRequired,
  pricePerMonth: PropTypes.number.isRequired,
  includedMinutes: PropTypes.number.isRequired
};

export default React.memo(RegionPhoneNumbers);
