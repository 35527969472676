import React, { useCallback } from "react";
import RegionSelect from "./RegionSelect";

function NumberRegionSelect() {
  const regionSelected = useCallback(region => {
    if (region !== null) {
      const { identifier } = region;
      window.location = `/numbers/geographic/${identifier}`;
    }
  }, []);

  return (
    <RegionSelect allowRegionChange={false} regionSelected={regionSelected} />
  );
}

export default React.memo(NumberRegionSelect);
