import React, { Fragment, PureComponent } from "react";
import About from "./components/About";
import Confirm from "./components/Confirm";
import ConfirmMobile from "./components/ConfirmMobile";
import Edit from "./components/Edit";
import { getScrollbarWidth } from "../../../utils";

function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    const c = ca[i];

    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }

    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }

  return "";
}

function consentValuePresent(prevState, currentState) {
  return prevState.consent === null && currentState.consent !== null;
}

const Dialog = ({ children }) => (
  <Fragment>
    <div className="overlay" />
    <div className="dialog">
      <div className="cookie-banner">{children}</div>
    </div>
  </Fragment>
);

class CookieBanner extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      consent: getCookie("cookie_consent") || null,
      screen: "confirm",
    };
  }

  aboutCookies = () => {
    this.setState({
      screen: "about",
    });
  };

  allowCookies = (cookies) => {
    const consent =
      cookies === "all"
        ? { analytics: true, chat: true, tracking: true }
        : ["analytics", "chat", "tracking"].reduce(
            (allowed, identifier) => ({
              ...allowed,
              [identifier]: cookies.includes(identifier),
            }),
            {}
          );

    this.setState({ consent });
  };

  editSettings = () => {
    this.setState({
      screen: "edit",
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (consentValuePresent(prevState, this.state)) {
      this.remember();
    }
  }

  remember() {
    const date = new Date();
    const time = date.getTime();
    const { consent } = this.state;

    const purposes = ["analytics", "chat", "tracking"];
    const days = purposes.every((identifier) => consent[identifier]) ? 180 : 7;

    date.setTime(time + days * 24 * 60 * 60 * 1000);

    // v1|true|true|true
    const value = [
      "v1",
      ...purposes.map((identifier) => consent[identifier]),
    ].join("|");

    document.cookie = `cookie_consent=${value}; expires=${date.toUTCString()}; path=/`;

    window.dataLayer &&
      window.dataLayer.push({
        event: "cookieConsent",
      });
  }

  render() {
    const { consent } = this.state;

    if (consent !== null) {
      return null;
    }

    return this.renderScreen();
  }

  renderScreen() {
    const { screen } = this.state;

    switch (screen) {
      case "about":
        return (
          <Dialog>
            <About
              allowCookies={this.allowCookies}
              editSettings={this.editSettings}
            />
          </Dialog>
        );

      case "confirm":
        const { matches } = window.matchMedia("(max-width: 767px)");

        return matches ? (
          <ConfirmMobile
            aboutCookies={this.aboutCookies}
            allowCookies={this.allowCookies}
          />
        ) : (
          <Dialog>
            <Confirm
              aboutCookies={this.aboutCookies}
              allowCookies={this.allowCookies}
            />
          </Dialog>
        );

      case "edit":
        return (
          <Dialog>
            <Edit allowCookies={this.allowCookies} />
          </Dialog>
        );
    }
  }
}

export default CookieBanner;
