import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

const TYPES = Object.freeze({
  local: {
    color: "pink",
    heading: "Local Numbers",
    prefix: "01/02",
    text:
      "Whether your business is in the city, or whether you simply have customers there, calls to your chosen landline number can be diverted to your phone, wherever you are."
  },
  national: {
    color: "navy",
    heading: "National Numbers",
    prefix: "0330",
    text:
      "Give your business a professional look, with a dedicated national telephone number. Boost your national presence, with a number that isn’t tied to one area code."
  }
});

function NumberTypes({ active, onChoose }) {
  return (
    <ul className="number-types">
      {Object.entries(TYPES).map(type => {
        const [key, value] = type;
        const { color, heading, prefix, text } = value;

        const className = classnames({
          "number-type": true,
          [`number-type--${color}`]: true,
          active: active === key
        });

        return (
          <li className={className} key={key}>
            <h2 className="number-type__title">
              {prefix} <span className="number-type__accent">{heading}</span>
            </h2>
            <p className="number-type__text">{text}</p>
            <button
              className="button button--medium button--white"
              onClick={() => onChoose(key)}
              type="button"
            >
              Choose your number
            </button>
          </li>
        );
      })}
    </ul>
  );
}

NumberTypes.propTypes = {
  active: PropTypes.string,
  onChoose: PropTypes.func.isRequired
};

export default NumberTypes;
