import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import classnames from "classnames";

function Button({ onClick, text }) {
  return (
    <button className="phone-number__button" onClick={onClick} type="button">
      <span className="u-visually-hidden">{text}</span>
    </button>
  );
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

Button = React.memo(Button);

function Heading({ number, selected }) {
  const [prefix, suffix] = useMemo(() => {
    const [first, ...rest] = number.split(" ");
    return [first, rest.join(" ")];
  }, []);

  const className = useMemo(
    () =>
      classnames({
        "phone-number__heading": true,
        "phone-number__heading--selected": selected
      }),
    [selected]
  );

  return (
    <h3 className={className}>
      {prefix} <span>{suffix}</span>
    </h3>
  );
}

Heading.propTypes = {
  number: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired
};

Heading = React.memo(Heading);

function PhoneNumber({ number, numberChosen, selected }) {
  const className = classnames({
    "phone-number": true,
    "phone-number--selected": selected
  });

  const onClick = useCallback(() => numberChosen(number), []);

  return (
    <li className={className}>
      <Heading number={number.number} selected={selected} />
      {!selected && (
        <Button text={`Choose ${number.number}`} onClick={onClick} />
      )}
    </li>
  );
}

PhoneNumber.propTypes = {
  number: PropTypes.object.isRequired,
  numberChosen: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired
};

export default React.memo(PhoneNumber);
